import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import login from "./assets/images/login.png";
import logo from "./assets/logo_v1.png";
import eHide from "./assets/images/icons/eyeHide.png";
import eView from "./assets/images/icons/eye.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import request from "./services/request";
import endPoint from "./services/endPoint";
import { AppContext } from "./services/context";
import InputField from "./Components/InputField";
import { toast } from "react-toastify";

const initValue = {
  user: "ounoid@gmail.com",
  password: "12345678",
};
const Login = () => {
  const authCtx = useContext(AppContext);
  const { user, onUserChange } = authCtx;
  const [inputType, setInputType] = useState("password");
  const [viewContent, setViewContent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    isAuth();
  }, [user.isAuth]);

  const validateData = Yup.object({
    user: Yup.string()
      .email("Adresse e-mail invalide")
      .required("Ce champ est obligatoire. Veuillez le remplir pour continuer"),
    password: Yup.string()
      .min(8, "Le mot de passe doit contenir 8 caractères ou moins")
      .required("Ce champ est obligatoire. Veuillez le remplir pour continuer"),
  });

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: validateData,
    onSubmit: (values) => {
      console.log(values);
      handleSubmit(values);
    },
  });

  const handleSubmitOld = (data) => {
    //
    request
      .post(endPoint.login, data)
      .then((res) => {
        console.log(res.data);
        onUserChange({
          isAuth: true,
          roles: res.data.user.profile,
          name: res.data.user.firstname + " " + res.data.user.lastname,
          token: res.data.access_token,
          token_refresh: null,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (values) => {
    //console.log(data);

    toast.promise(request.post(endPoint.login, values), {
      pending: "Veuillez patienté...",
      success: {
        render({ data }) {
          console.log(data);
          const res = data;
          onUserChange({
            isAuth: true,
            roles: res.data.user.profile,
            name: res.data.user.firstname + " " + res.data.user.lastname,
            token: res.data.access_token,
            token_refresh: null,
          });
          return res.data.message;
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          if (!data?.response?.data?.user?.email_verified_at) {
            onUserChange({
              ...user,
              user: values.user,
            });
            //window.location.href="/verification-du-code-otp"
          }
          if (data?.response?.data?.message) {
            return data?.response?.data?.message;
          }
          return data?.response?.data?.errors
            ? data?.response?.data?.errors
            : data?.response?.data?.error;
        },
      },
    });
  };

  const isAuth = () => {
    if (user.isAuth === true && user.token != null && user.token !== "") {
      console.log(`connexion reussi, isAuth: ${user}`);
      console.log(user);

      return navigate("/dashboard/");
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-10 mx-auto">
        <div className="row">
          <div className="col-12 col-lg-5 order-2 order-md-1 pt-5 mx-auto">
            <div className="card">
              <img src={logo} className="w-100" />
            </div>
            <div className="text-center">
              <h1 className="fs-48 fw-bold m-0 mt-3 text-primary text-uppercase">connexion</h1>
              <span className="d-inline-block mb-3">
                Heureux de vous revoir
              </span>
            </div>
            <form onSubmit={formik.handleSubmit} className="">
              <InputField
                type="email"
                name="user"
                formik={formik}
                placeholder="Entrer votre email"
              />

              <div className="position-relative">
                <InputField
                  type={inputType}
                  name="password"
                  formik={formik}
                  placeholder="Entrer votre mot de passe"
                >
                  <img
                    className="position-absolute eye-position"
                    src={viewContent ? eView : eHide}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      if (inputType === "password") {
                        setInputType("text");
                        setViewContent(!viewContent);
                      } else {
                        setInputType("password");
                        setViewContent(!viewContent);
                      }
                    }}
                  />
                </InputField>
              </div>

              <div className="checkbox mb-3 position-relative">
                <label className="text-small align-middle">
                  
                </label>
                <Link
                  to="#"
                  className="fs-14 text-black link text-decoration-none position-absolute top-0 end-0 text-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#forgetPassword"
                >
                  Mot de passe oublié ?
                </Link>
              </div>
              <button
                type="submit"
                className="w-100 btn btn-lg btn-primary p-0"
              >
                Se connecter
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
