import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import edit from "../assets/images/icons/edit.png";
import PageHeader from "../Components/PageHeader";
import Table from "../Components/Table";
import TableContent from "../Components/TableContent";
import TableHeader from "../Components/TableHeader";
import * as Yup from "yup";
import InputField from "../Components/InputField";
import request, { URL } from "../services/request";
import endPoint from "../services/endPoint";
import { AppContext } from "../services/context";
import Notify from "../Components/Notify";
import { toast } from "react-toastify";

const initData = {
  carburant_type: "",
  transmission: "",
  condition: "",
  kilometrage: "",
  garantie_moteur: "",
  prix: "",
  model: "",
  categorie: "",
  pays: "",
  image: "",
  description: "",
};

const validateData = Yup.object({
  marque1: Yup.string()
    .min(3, "Le nom de la marque doit contenir 3 caractères ou moins")
    .required("Ce champ est obligatoire. Veuillez le remplir pour continuer"),
  description: Yup.string()
    .min(3, "La description doit contenir 3 caractères ou moins")
    .required("Ce champ est obligatoire. Veuillez le remplir pour continuer"),
  /*image: Yup.mixed()
    .required("Une image est requise")
    .test(
      "fileFormat",
      "Seuls les fichiers jpeg, png et gif sont autorisés",
      (value) => {
        return (
          value &&
          ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        );
      }
    )
    .test("fileSize", "La taille maximale autorisée est de 2 Mo", (value) => {
      return value && value.size <= 2 * 1024 * 1024;
    }),*/
});

const Vehicule = () => {
  const authCtx = useContext(AppContext);
  const { user } = authCtx;
  const [datas, setDatas] = useState([]);
  const [editId, setEditId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [marques, setMarques] = useState([]);
  const [annees, setAnnees] = useState([]);
  const [models, setModels] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pays, setPays] = useState([]);
  const [viewData, setViewData] = useState({});
  const modal = useRef();
  const header = {
    headers: {
      Authorization: `Bearer ${user.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    getAll();
    getMarques();
    getAnnee();
    getModels();
    getCategorie();
    getPays();
  }, [refresh]);

  const formik = useFormik({
    initialValues: initData,
    //validationSchema: validateData,
    onSubmit: (values) => {
      console.log(values);
      values.marque = values.marque.value;
      values.annee = values.annee.value;
      values.model = values.model.value;
      values.categorie = values.categorie.value;
      values.pays = values.pays.value;
      values.carburant_type = values.carburant_type.value;
      values.transmission = values.transmission.value;
      values.condition = values.condition.value;
      if (editId === "") {
        handleSubmit(values);
      } else {
        values._method = "put";
        handleEditSubmit(values);
      }
    },
  });

  const getMarques = () => {
    request
      .get(endPoint.marques, header)
      .then((res) => {
        const tab = res.data.data.map((data) => {
          return { ...data, value: data.slug, label: data.label };
        });
        setMarques(tab);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAnnee = () => {
    request
      .get(endPoint.annees, header)
      .then((res) => {
        const tab = res.data.data.map((data) => {
          return { ...data, value: data.slug, label: data.label };
        });
        setAnnees(tab);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModels = () => {
    request
      .get(endPoint.models, header)
      .then((res) => {
        const tab = res.data.data.map((data) => {
          return { ...data, value: data.slug, label: data.label };
        });
        setModels(tab);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategorie = () => {
    request
      .get(endPoint.categories, header)
      .then((res) => {
        const tab = res.data.data.map((data) => {
          return { ...data, value: data.slug, label: data.label };
        });
        setCategories(tab);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPays = () => {
    request
      .get(endPoint.pays, header)
      .then((res) => {
        const tab = res.data.data.map((data) => {
          return { ...data, value: data.slug, label: data.label };
        });
        setPays(tab);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAll = () => {
    request
      .get(endPoint.vehicules, header)
      .then((res) => {
        setDatas(res.data.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmit = (values) => {
    toast.promise(request.post(endPoint.vehicules, values, header), {
      pending: "Veuillez patienté...",
      success: {
        render({ data }) {
          console.log(data);
          const res = data;
          modal.current.click();
          getAll();

          return res.data.message;
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          if (data?.response?.data?.message) {
            return data?.response?.data?.message;
          }
          return data?.response?.data?.errors
            ? data?.response?.data?.errors
            : data?.response?.data?.error;
        },
      },
    });
  };
  const handleEditSubmit = (values) => {
    //setShowModal(true)
    console.log(values);
    toast.promise(
      request.post(endPoint.vehicules + "/" + editId, values, header),
      {
        pending: "Veuillez patienté...",
        success: {
          render({ data }) {
            console.log(data);
            const res = data;
            modal.current.click();
            getAll();

            return res.data.message;
          },
        },
        error: {
          render({ data }) {
            console.log(data);
            if (data?.response?.data?.message) {
              return data?.response?.data?.message;
            }
            return data?.response?.data?.errors
              ? data?.response?.data?.errors
              : data?.response?.data?.error;
          },
        },
      }
    );
  };

  const onDelete = () => {
    toast.promise(
      request.delete(endPoint.vehicules + "/" + viewData.slug, header),
      {
        pending: "Veuillez patienté...",
        success: {
          render({ data }) {
            console.log(data);
            const res = data;

            getAll();

            return res.data.message;
          },
        },
        error: {
          render({ data }) {
            console.log(data);
            if (data?.response?.data?.message) {
              return data?.response?.data?.message;
            }
            return data?.response?.data?.errors
              ? data?.response?.data?.errors
              : data?.response?.data?.error;
          },
        },
      }
    );
  };

  const addModal = (e) => {
    e.preventDefault();
    setEditId("");
    formik.resetForm();
  };

  const editData = (e, data) => {
    e.preventDefault();
    setEditId(data.slug);
    formik.setFieldValue("kilometrage", data.kilometrage);
    formik.setFieldValue("prix", data.prix);
    formik.setFieldValue("garantie_moteur", data.garantie_moteur);
    formik.setFieldValue("description", data.description);
    formik.setFieldValue("marque", {
      ...data,
      value: data.model_marque.marque.slug,
      label: data.model_marque.marque.label,
    });
    formik.setFieldValue("annee", {
      ...data,
      value: data.model_marque.annee.slug,
      label: data.model_marque.annee.label,
    });
    formik.setFieldValue("model", {
      ...data,
      value: data.model_marque.slug,
      label: data.model_marque.label,
    });
    formik.setFieldValue("categorie", {
      ...data,
      value: data.categorie.slug,
      label: data.categorie.label,
    });
    formik.setFieldValue("pays", {
      ...data,
      value: data.pays.slug,
      label: data.pays.label,
    });
    formik.setFieldValue("carburant_type", {
      value: data.carburant_type,
      label: data.carburant_type,
    });
    formik.setFieldValue("transmission", {
      value: data.transmission,
      label: data.transmission,
    });
    formik.setFieldValue("condition", {
      value: data.condition,
      label: data.condition,
    });
  };
  return (
    <>
      {/**
       * <div className="row">
        <div className="col-12">
          <div className="row row-cols-1 row-cols-sm-4">
            {[
              { name: "Marques", img: tag },
              { name: "Models", img: tag },
              { name: "Années", img: tag },
            ].map((data, idx) => {
              return (
                <div className="col mb-3" key={idx}>
                  <div className="card btn-primary-light p-4">
                    <div className="d-flex align-items-center">
                      <div className="text-center">
                        <h6 className="text-16 fw-bold fs-4">{data.name}</h6>
                      </div>
                      <div className="ms-auto">
                        <img width="40px" src={data.img} alt="" />
                      </div>
                    </div>
                    <div>
                      <span>145</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
       */}
      <PageHeader
        title="Liste des véhicules"
        modal="form"
        addModal={addModal}
      />
      <Table>
        <TableHeader>
          <th scope="col" className="border-raduis-left">
            #
          </th>
          <th scope="col">Logo</th>
          <th scope="col">Marques</th>
          <th scope="col">Année</th>
          <th scope="col">Model</th>
          <th scope="col">Carburant</th>
          <th scope="col">Transmission</th>
          <th scope="col">Prix</th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </TableHeader>
        <TableContent>
          {datas.map((data, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <input type="checkbox" value="selected" />
                </td>
                <td className="fw-bold">
                  <img
                    width="64px"
                    height="64px"
                    className="rounded"
                    src={URL + data.image}
                    alt=""
                  />
                </td>
                <td className="fw-bold">{data.model_marque.marque.label}</td>
                <td className="fw-bold">{data.model_marque.annee.label}</td>
                <td className="fw-bold">{data.model_marque.label}</td>
                <td className="fw-bold">{data.carburant_type}</td>
                <td className="fw-bold">{data.transmission}</td>
                <td className="fw-bold">{data.prix} FCFA</td>
                <td className="text-center">
                  <div className="btn-group">
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-primary-light"
                        data-bs-toggle="modal"
                        data-bs-target="#view"
                        onClick={(e) => {
                          setViewData(data);
                        }}
                      >
                        <span>
                          {" "}
                          <i className="bi bi-eye-fill"></i>
                        </span>
                      </button>
                    </div>
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-primary-light"
                        data-bs-toggle="modal"
                        data-bs-target="#form"
                        onClick={(e) => {
                          editData(e, data);
                        }}
                      >
                        <span>
                          {" "}
                          <i className="bi bi-pencil-square"></i>
                        </span>
                      </button>
                    </div>
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                        onClick={(e) => {
                          setViewData(data);
                        }}
                      >
                        <span>
                          <i className="bi bi-trash3-fill"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </TableContent>
      </Table>
      <div className="modal fade" id="form">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                {editId !== ""
                  ? "Modification d’un véhicule"
                  : "Ajout d’un véhicule"}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form className="row" onSubmit={formik.handleSubmit}>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="marque"
                    formik={formik}
                    placeholder="Sélectionnez une marque"
                    label={"Marque"}
                    options={marques}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="annee"
                    formik={formik}
                    placeholder="Sélectionnez une année"
                    label={"Année"}
                    options={annees}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="model"
                    formik={formik}
                    placeholder="Sélectionnez un model"
                    label={"Model"}
                    options={models}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="categorie"
                    formik={formik}
                    label={"Catégorie"}
                    placeholder="Sélectionnez une catégorie"
                    options={categories}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="pays"
                    formik={formik}
                    placeholder="Sélectionnez un pays"
                    label={"Pays"}
                    options={pays}
                  />
                </div>
                
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="carburant_type"
                    formik={formik}
                    placeholder="Sélectionnez le type de carburant"
                    label={"Carburant"}
                    options={[
                      { value: "Essence", label: "Essence" },
                      { value: "Diesel", label: "Diesel" },
                      { value: "Electrique", label: "Electrique" },
                      { value: "Hybride", label: "Hybride" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="transmission"
                    formik={formik}
                    placeholder="Sélectionnez le type de transmission"
                    label={"Transmission"}
                    options={[
                      { value: "Manuelle", label: "Manuelle" },
                      { value: "Automatique", label: "Automatique" },
                      { value: "Semi-Automatique", label: "Semi-Automatique" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"react-select"}
                    name="condition"
                    formik={formik}
                    placeholder="Sélectionnez une condition"
                    label={"Condition"}
                    options={[
                      { value: "Neuf", label: "Neuf" },
                      { value: "Occasion", label: "Occasion" },
                      { value: "Reconditionné", label: "Reconditionné" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"text"}
                    name="kilometrage"
                    formik={formik}
                    placeholder="Entrez le kilometrage"
                    label={"Kilometrage"}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"text"}
                    name="garantie_moteur"
                    formik={formik}
                    placeholder="Entrez la garantie moteur"
                    label={"Garantie moteur"}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    type={"text"}
                    name="prix"
                    formik={formik}
                    placeholder="Entrez le prix"
                    label={"Prix"}
                  />
                </div>

                <InputField
                  type={"file"}
                  name="image"
                  formik={formik}
                  placeholder="Choisir une image"
                  label={"Image du véhicule"}
                />

                <InputField
                  type={"textaera"}
                  name="description"
                  formik={formik}
                  placeholder="Description de la marque"
                  label={"Description"}
                />

                <div className="d-flex justify-content-start border-0">
                  <button
                    type="reset"
                    className="btn btn-primary-light me-2"
                    data-bs-dismiss="modal"
                    ref={modal}
                  >
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="delete">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">Suppression</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div>Voulez-vous supprimer définitivement les données ?</div>
              <div className="mt-4 d-flex justify-content-end">
                <button
                  className="btn btn-primary me-2"
                  data-bs-dismiss="modal"
                >
                  Non
                </button>
                <button
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={onDelete}
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="view">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                Informations
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body text-center1">
              <div className="mb-3">
                <img width={"40%"} src={URL + viewData.image} />
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">Marque : </span>
                <span className="d-inline-block">
                  {viewData.model_marque?.marque?.label}
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">Année : </span>
                <span className="d-inline-block">
                  {viewData.model_marque?.annee?.label}
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">Model : </span>
                <span className="d-inline-block">
                  {viewData.model_marque?.label}
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">Prix : </span>
                <span className="d-inline-block">{viewData.prix} FCFA</span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">
                  Kilometrage :{" "}
                </span>
                <span className="d-inline-block">
                  {viewData.kilometrage} km
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">
                  Transmission :{" "}
                </span>
                <span className="d-inline-block">{viewData.transmission}</span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">
                  Carburant :{" "}
                </span>
                <span className="d-inline-block">
                  {viewData.carburant_type}
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">
                  Garantie moteur :{" "}
                </span>
                <span className="d-inline-block">
                  {viewData.garantie_moteur} Mois
                </span>
              </div>
              <div>
                <span className="fw-bold d-inline-block me-2">
                  Description :{" "}
                </span>
                <span className="d-inline-block">{viewData.description}</span>
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <button className="btn btn-primary" data-bs-dismiss="modal">
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notify showModal={showModal} />
    </>
  );
};

export default Vehicule;
