

const endPoint = {
    login:"api/login",
    marques:"api/marques",
    models:"api/models",
    annees:"api/annees",
    categories:"api/categories",
    pays:"api/pays",
    vehicules:"api/vehicules",


    produits:"api/produits",
    promotions:"promotions",
    livreurs:"livreurs",
    fournisseurs:"fournisseurs",
    clients:"clients",
    administrateurs:"administrateurs",
}

export default endPoint